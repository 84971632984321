var openModal = function (t, opts) {
    t.modal({
        url: '../modal.html',
        height: 500,
        fullscreen: false,
    });
};

var getBadge = function(t) {
    return t.get('board', 'shared', 'prefix')
        .then(function (prefix) {
            return t.card('all').then(function (card) {
                let text = "#" + card.idShort;
                
                if(prefix) {
                    text = "#" + prefix + "-" + card.idShort
                }

                return [{
                    text: text
                }];
            });
        });
}

window.TrelloPowerUp.initialize({
    'board-buttons': function (t, opts) {
        return [{
            icon: {
                dark: "https://card-numbers.trello.stayumble.com/assets/svgs/sliders-v.svg",
                light: "https://card-numbers.trello.stayumble.com/assets/svgs/sliders-v-white.svg"
            },
            text: 'Umble Card Numbers',
            callback: openModal,
            condition: 'edit',
        }];
    },
    'card-badges': function (t, opts) {
        return getBadge(t);
    },
    'card-detail-badges': function (t, opts) {
        return getBadge(t);
    }
});